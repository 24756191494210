<template lang="pug">
div
  AppAlertCard(:title="$t('wizard_contract.end.title')" :subtitle="$t('wizard_contract.end.subtitle')" :label="$t('wizard_contract.end.label')") 
    AppRow.mb-8.text-center
      AppDescription(label="Folio"  :description="invoice.folio_seguimiento")
      AppDescription(label="Numero de servicio" :description="invoice.numero_contrato" v-if="invoice.numero_contrato")
</template>

<script>
import AppAlertCard from "@/components/AppAlertCard";
import AppRow from "@/components/AppRow";
import AppDescription from "@/components/AppDescription";
import { onMounted, ref } from "@vue/runtime-core";
import { store } from "@/store";
import { axios } from "@/utils/axios";
export default {
  components: { AppAlertCard, AppRow, AppDescription },
  props: {
    state: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const invoice = ref({});
    onMounted(async () => {
      if (!props.state.lead_id) return;
      try {
        store.APP_LOADING = true;
        const { data } = await axios.get(`lead/${props.state.lead_id}`);
        invoice.value = data.payload[0];
      } catch (error) {
        console.log(error);
      } finally {
        store.APP_LOADING = false;
      }
    });
    return { invoice };
  },
};
</script>

<style lang="scss" scoped></style>
