<template lang="pug">
AppCard
	h1.text-center.title-step {{$t('wizard_contract.plans.title')}}
	.plan-container.plan-box
		AppPlanPromotion(v-for="(plan, index) in listPlans" v-bind="plan" :key="index" :active="indicePlan === index" @click="indicePlan = index")
		AppPlan(v-for="(plan, index) in listPlans" v-bind="plan" :key="index" :active="indicePlan === index" @click="indicePlan = index")
	AppRow.mt-5.btns-steps
		AppButton.mb-0.bg-gignetgreen-500(:label="$t('wizard_contract.address.btn_back')" @click="backStep")
		AppButton.mb-0.bg-gignetgreen-500(:label="$t('wizard_contract.address.btn_send')" @click="choosePlan")
</template>
<script>
import AppCard from "@/components/AppCard";
import AppPlan from "@/components/Plans/PlanBlue";
import AppPlanPromotion from "@/components/Plans/PlanPromotion";
import AppButton from "@/components/AppButton";
import AppRow from "@/components/AppRow";
import { axios } from "@/utils/axios";
import { onMounted, ref } from "vue";
import { store } from "@/store";
export default {
  emits: ["next", "previous"],
  props: {
    state: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    AppCard,
    AppPlan,
    AppPlanPromotion,
    AppButton,
    AppRow,
  },
  setup(props, { emit }) {
    const listPlans = ref([]);
    const listTarifasActivas = ref([]);
    const listTarifasByAsentamiento = ref([]);
    const indicePlan = ref(1);
    const getPlans = async () => {
      const { data } = await axios.get("planinternet/");
      listTarifasActivas.value = data.payload;
    };

    const getPlansByAsentamiento = async () => {
      const { data } = await axios.get(
        "planinternet/asentamiento/" + store.asentamiento_id
      );
      listTarifasByAsentamiento.value = data.payload;
    };

    const loadPlans = async () => {
      await getPlans();
      await getPlansByAsentamiento();

      const plans = listTarifasActivas.value.concat(
        listTarifasByAsentamiento.value
      );
      listPlans.value = plans.sort((a, b) =>
        a.velocidad_incluida > b.velocidad_incluida ? 1 : -1
      );

      for (let i in listPlans.value) {
        if (listPlans.value[i].is_promotion == false) {
          if (listPlans.value[i].text_promotion == false) {
            listPlans.value[i].text_promotion = "";
          }
          if (listPlans.value[i].description_promotion == false) {
            listPlans.value[i].description_promotion = "";
          }
        }
      }
      console.log("***");
      console.log(listPlans.value);
      console.log("***");
    };

    onMounted(async () => {
      try {
        store.APP_LOADING = true;
        store.step = 2;
        await loadPlans();
      } catch (error) {
        console.log(error);
      } finally {
        store.APP_LOADING = false;
      }
    });

    const backStep = async () => {
      console.log("backStep...");
      store.cobertura = false;
      emit("previous");
    };

    const choosePlan = async () => {
      console.log("choosePlan");
      console.log(listPlans.value[indicePlan.value].id);
      store.plan_id = listPlans.value[indicePlan.value].id;
      localStorage.plan_id = store.plan_id;
      emit("next");
    };

    return {
      getPlans,
      listPlans,
      indicePlan,
      backStep,
      choosePlan,
      getPlansByAsentamiento,
      loadPlans,
    };
  },
};
</script>
<style lang="scss" scoped>
.bg-gignetgreen-500 {
  background-color: rgba(21, 199, 208, var(--tw-bg-opacity));
}
.bg-gignetgreen-500:hover {
  background-color: rgba(18, 172, 180, var(--tw-bg-opacity));
}

.plan-box {
  margin-top: 10px;
}

.title-step {
  font-weight: bold;
  color: #2a5aad;
  margin-bottom: 20px;
}

.plan-container {
  @apply grid grid-flow-row gap-x-8 gap-y-10 px-6;
  @screen md {
    @apply grid-cols-3;
  }
}

.btns-steps {
  margin-top: 30px;
}
</style>
