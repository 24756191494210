<template>
  <div class="md:w-full py-6 mx-auto mb-2 md:mb-10">
    <div class="flex">
      <div class="w-1/4" v-for="(step, index) in steps" :key="index">
        <div class="relative mb-2">
          <div
            v-if="index > 0"
            class="
              absolute
              flex
              align-center
              items-center
              align-middle
              content-center
            "
            style="
              z-index: -1;
              width: calc(100% - 2.5rem - 1rem);
              top: 50%;
              transform: translate(-50%, -50%);
            "
          >
            <div
              class="
                w-full
                bg-gray-200
                rounded
                items-center
                align-middle align-center
                flex-1
              "
            >
              <div
                class="w-0 bg-gignetgreen-500 py-1 rounded"
                :style="`width: ${getWidth(index)}%`"
              ></div>
            </div>
          </div>

          <div
            v-if="active >= index"
            class="
              w-10
              h-10
              md:w-16 md:h-16
              mx-auto
              bg-gignetgreen-500
              rounded-full
              text-lg text-white
              flex
              items-center
              z-10
            "
          >
            <span
              class="
                relative
                text-center text-lg
                md:text-3xl
                text-white
                w-full
                z-10
              "
            >
              <i :class="step.icon" />
            </span>
          </div>

          <div
            v-else
            class="
              w-10
              h-10
              md:w-16 md:h-16
              mx-auto
              bg-bluegray-300
              border-2 border-bluegray-300
              rounded-full
              text-lg text-white
              flex
              items-center
              z-10
            "
          >
            <span
              class="
                relative
                text-center text-lg
                md:text-3xl
                text-gray-600
                w-full
                z-10
              "
            >
              <i :class="step.icon" />
            </span>
          </div>
        </div>

        <div
          class="
            hidden
            md:block
            text-xs text-center
            md:text-sm
            text-bluegray-500
          "
        >
          {{ step.label }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted } from "@vue/runtime-core";
import { store } from "@/store";
export default {
  props: {
    steps: {
      type: Array,
      default: () => [],
    },
    active: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    function getWidth(n) {
      if (props.active + 1 < n) {
        return 0;
      }

      if (props.active + 1 === n) {
        return 50;
      }

      if (props.active + 1 > n) {
        return 100;
      }
    }

    onMounted(() => {
      store.APP_LOADING = false;
    });

    return { getWidth };
  },
};
</script>
