<template lang="pug">
.plan(:class="{ active }")
  h1.nombre {{ nombre_comercial }}
  h3.velocidad 
    span {{ velocidad_incluida }}
    | Mbps
  span.plazo plazo {{ plazo_minimo_meses }} meses
  .renta
    span desde
    span.renta_precio {{ numberFormatter(renta_mensual_con_iva) }}
    span al mes
  span.instalacion(v-if="texto_tarifa_descripcion") {{ texto_tarifa_descripcion }}
</template>

<script>
import { numberFormatter } from "@/utils/formatters";

export default {
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: 0,
    },
    plazo_minimo_meses: {
      type: Number,
      default: 0,
    },
    nombre_comercial: {
      type: String,
      default: "",
    },
    velocidad_incluida: {
      type: Number,
      default: 0,
    },
    instalacion_sin_iva: {
      type: Number,
      default: 0,
    },
    renta_mensual_con_iva: {
      type: Number,
      default: 0,
    },
    texto_tarifa_descripcion: {
      type: [String, Boolean],
      default: "",
    },
    plan_servicio_internet: {
      type: Array,
      default: () => [0, ""],
    },
  },
  setup() {
    return { numberFormatter };
  },
};
</script>

<style lang="scss" scoped>
.plan {
  @apply select-none;
  @apply border;
  @apply border-bluegray-200;
  @apply rounded-md;
  @apply py-8;
  @apply flex;
  @apply flex-col;
  @apply items-center;
  @apply text-center;
  @apply ring ring-transparent;

  @apply transition-all;
  @apply transform;
  &.active {
    @apply scale-105;
    @apply border-transparent;
    @apply ring ring-gignetgreen-400;
  }
  &:active {
    @apply scale-110;
  }
}

.nombre {
  @apply font-medium;
  @apply text-sm;
  @apply text-bluegray-400;
  @apply mb-4;
}

.plazo {
  @apply mb-4;
  @apply text-bluegray-500;
}

.renta {
  @apply flex;
  @apply flex-col;
  @apply mb-8;
  @apply text-bluegray-500;
  .renta_precio {
    @apply text-2xl;
    @apply text-gignetgreen-500;
  }
}

.velocidad {
  @apply text-bluegray-600;
  @apply text-2xl;
  @apply font-semibold;
  span {
    @apply text-6xl;
    @apply text-gignetgreen-500;
    @apply font-bold;
  }
}

.instalacion {
  @apply text-bluegray-400;
  @apply text-xs;
  @apply font-light;
}
</style>
