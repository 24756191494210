<template lang="pug">
form(@submit.prevent="onSubmit")
	AppCard
		h1 {{$t('contract.contact_form.form_name')}}
		.row
			AppFormInput(type="text" :label="$t('contract.contact_form.name')" name="name" required)
			AppFormInput(type="text" :label="$t('contract.contact_form.id_vendedor')" name="vendedor_preventa" :value="id_vendedor" :readonly="!!id_vendedor")
		.row
			AppFormInput(type="email" :label="$t('contract.contact_form.email')" name="email_from" required)
			.row.mb-2
				AppFormInput(type="text" :label="$t('contract.contact_form.phone')" name="phone" required)
				AppFormInput(type="text" :label="$t('contract.contact_form.mobile')" name="mobile")
		.row
			AppFileUpload.mb-4(
			icon="user-square"
			name="identificacion_file"
			:label="$t('contract.contact_form.identificacion')"
			)
			AppFileUpload.mb-4(
			icon="map"
			name="comprobante_domicilio_file"
			:label="$t('contract.contact_form.comprobante')"
			)
		.row.mt-4
			AppCheckbox(:label="$t('contract.contact_form.invoice_check')"  v-model="checkvalue")
		div(v-show="checkvalue")
			.row
				AppFormInput(type="text" :label="$t('contract.contact_form.business_name')" name="razon_social" :required="checkvalue")
				AppFormInput(type="text" :label="$t('contract.contact_form.identification')" name="rfc_sat" :required="checkvalue" pattern="[A-ZÑ&amp;]{3,4}[0-9]{2}[0-1][0-9][0-3][0-9][A-Z0-9]?[A-Z0-9]?[0-9A-Z]?")
				AppFormInput(type="number" :label="$t('contract.address_form.zip_code')" name="cp_partner" :required="checkvalue")
			.row
				AppFormInput(type="select" v-model:value="country_id_partner" label="Country" name="country_id_partner" :options="countries" :required="checkvalue" v-show="true" disabled)
				AppFormInput(type="select" v-model:value="state_id_partner" label="States" :options="estados" name="state_id_partner" :required="checkvalue" v-show="true" @change="changeState")
				AppFormInput(type="select" v-model:value="city_id_partner" label="City" name="city_id_partner" :options="cities" :required="checkvalue" v-show="true")
				AppFormInput(type="select" v-model:value="locality_id_partner" label="Locality" name="l10n_mx_edi_locality_id" :options="localities" :required="checkvalue" v-show="true")
			.row
				AppFormInput(type="text" label="street_name" name="street_name" :required="checkvalue")
				AppFormInput(type="text" label="street_number" name="street_number" :required="checkvalue")
				AppFormInput(type="text" label="street_number2" name="street_number2" :required="checkvalue")
				AppFormInput(type="text" label="Colonia" name="l10n_mx_edi_colony" :required="checkvalue")
			.row
				AppFileUpload.mb-4(icon="map" name="constancia_situacion_fiscal_file" label="Cedula Fiscal")
		h1.mt-6 {{$t('contract.address_form.form_name')}}
		.row.box-address
			.text-center
				span(class="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-blue-400 text-white rounded margin-badge")
					| {{serviceAddress.zona_name}}
				span(class="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-blue-400 text-white rounded margin-badge")
					| {{serviceAddress.asentamiento_name}}
				span(class="text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-blue-400 text-white rounded margin-badge")
					| {{serviceAddress.building_name}}
		.row
			AppFormInput(type="text" :label="$t('contract.address_form.street')" name="calle_servicio" required)
			.row
				AppFormInput(type="text" :label="$t('contract.address_form.ext')" name="num_ext_servicio" required)
				AppFormInput(type="text" :label="$t('contract.address_form.int')" name="num_int_servicio")
		.row
			AppFormInput(type="number" :label="$t('contract.address_form.zip_code')" name="cp_servicio" required)
			div
				span(class="label") {{$t('contract.address_form.referal')}}
				select(name="channel_contact" v-model="channel_id" class="input")
					option(:value="item.value" v-for="item in sources" :key="item.value")
						| {{item.label}}
			div(v-show="channel_id == 'otro'")
				span(class="label")
					| {{ $t('contract.address_form.channel_other') }}
				span(class="form-input__required")
					| *
				input(type="text" label="Canal" v-model="channel_other_option" class="input" :required="channel_id == 'otro'")
		AppFormInput(type="textarea" :label="$t('contract.address_form.aditional_info')" name="description")
		AppCheckbox(:label="$t('contract.contact_form.check')" v-model="checkValueConfirm")
		AppRow.mt-5.btns-steps
			AppButton.mb-0.bg-gignetgreen-500(:label="$t('wizard_contract.address.btn_back')" @click="backStep")
			AppButton.mb-0.bg-gignetgreen-500(:loading="loading" :disabled="loading || !checkValueConfirm" :label="$t('common.next')")
</template>

<script>
import { useRoute } from "vue-router";
import AppCard from "@/components/AppCard";
import AppFormInput from "@/components/Form/AppFormInput";
import AppFileUpload from "@/components/Form/AppFileUpload";
import AppCheckbox from "@/components/Form/AppCheckbox";
import AppButton from "@/components/AppButton";
import AppRow from "@/components/AppRow";
import { computed, onMounted, ref, watch } from "@vue/runtime-core";
import { store } from "@/store";
import { axios } from "@/utils/axios";
export default {
  props: {
    state: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ["previous", "next", "end"],
  components: {
    AppCard,
    AppFormInput,
    AppCheckbox,
    AppButton,
    AppFileUpload,
    AppRow,
  },
  setup(props, { emit }) {
    const route = useRoute();
    const listLocalities = ref([]);
    const currentCoverage = ref();
    const currentLocality = ref();
    const listCoverageZones = ref([]);
    const sources = ref([]);
    const checkvalue = ref(false);
    const checkValueConfirm = ref(false);
    const serviceAddress = ref({});
    const countries = ref([]);
    const country_id_partner = "156";
    const state_id_partner = ref("506");
    const estados = ref([]);
    const cities = ref([]);
    const city_id_partner = ref("1808");
    const localities = ref([]);
    const locality_id_partner = ref("525");
    const plan_request = ref([]);
    const channel_id = ref("website");
    const channel_other_option = ref("");

    const getCountries = async () => {
      const { data } = await axios.get("location/country");
      let arrayData = [];
      data.payload.map((item) => {
        arrayData.push({
          value: item.id.toString(),
          label: item.name,
        });
      });
      countries.value = arrayData;
    };

    const getStates = async (id) => {
      const { data } = await axios.get("location/country/" + id + "/state");
      let arrayData = [];
      data.payload.map((item) => {
        arrayData.push({
          value: item.id.toString(),
          label: item.name,
        });
      });
      estados.value = arrayData;
    };

    const getCities = async (id) => {
      const { data } = await axios.get("location/state/" + id + "/city");
      let arrayData = [];
      data.payload.map((item) => {
        arrayData.push({
          value: item.id.toString(),
          label: item.name,
        });
      });
      cities.value = arrayData;
    };

    const getLocality = async (id) => {
      const { data } = await axios.get("location/state/" + id + "/locality");
      let arrayData = [];
      data.payload.map((item) => {
        arrayData.push({
          value: item.id.toString(),
          label: item.name,
        });
      });
      localities.value = arrayData;
    };

    const getPlanRequest = async (id) => {
      const { data } = await axios.get("planinternet/tarifas/" + id + "");
      plan_request.value = data.payload[0];
      console.log("-----");
      console.log(plan_request.value);
      console.log("-----");
      console.log(plan_request.value.plan_servicio_internet[0]);
    };

    const changeState = async () => {
      console.log("changeState");
      await getCities(state_id_partner.value);
      await getLocality(state_id_partner.value);
    };

    const localitiesOptions = computed(() =>
      listLocalities.value.map((el) => ({
        value: el.id.toString(),
        label: el.name,
      }))
    );

    const typeLocality = computed(() => {
      let currentLocalityObject = listLocalities.value.find(
        (el) => el.id == currentLocality.value
      );

      if (!currentLocalityObject)
        return [{ value: "", label: "", locality_name: "" }];

      return [
        {
          value: currentLocalityObject.tipo_asentamiento_id[0],
          label: currentLocalityObject.tipo_asentamiento_id[1],
          locality_name: currentLocalityObject.name,
        },
      ];
    });

    const id_vendedor = computed(() => {
      return route?.query?.id;
    });
    const loading = computed(() => store.APP_LOADING);

    const backStep = async () => {
      console.log("backStep...");
      emit("previous");
    };

    onMounted(async () => {
      try {
        store.APP_LOADING = true;
        console.log("----------------");
        console.log(store.city_id);
        console.log(store.city_name);
        console.log(store.zona_id);
        console.log(store.zona_name);
        console.log(store.asentamiento_id);
        console.log(store.asentamiento_name);
        console.log(store.building_id);
        console.log(store.building_name);
        console.log(store.cobertura);
        console.log("----------------");

        getPlanRequest(store.plan_id);

        serviceAddress.value = {
          city_id: store.city_id,
          city_name: store.city_name,
          zona_id: store.zona_id,
          zona_name: store.zona_name,
          asentamiento_id: store.asentamiento_id,
          asentamiento_name: store.asentamiento_name,
          building_id: store.building_id,
          building_name: store.building_name,
        };

        getCountries();
        getStates("156");
        getCities("506");
        getLocality("506");

        const { data: coberturas } = await axios.get("lead/zonacobertura");
        const { data: sourcesArr } = await axios.get("source/channels");

        sources.value = sourcesArr.payload.map((el) => ({
          value: el.id,
          label: el.name,
        }));

        listCoverageZones.value = coberturas.payload.map((el) => ({
          value: el.id.toString(),
          label: el.name,
        }));

        currentCoverage.value = store.zona_id;
        currentLocality.value = store.asentamiento_id;
      } catch (error) {
        console.log(error);
      } finally {
        store.APP_LOADING = false;
      }
    });

    watch(currentCoverage, async (value) => {
      const { data } = await axios.get(`lead/asentamientos/${value}`);
      listLocalities.value = data.payload;
    });

    const onSubmit = async (e) => {
      try {
        console.log("Submit..");
        store.APP_LOADING = true;
        const formData = {};

        //Validamos que existan los inputs de file
        if (!e.target.identificacion_file.value) {
          return window.alert("La imagen de Identifiación es obligatoria");
        }

        if (!e.target.comprobante_domicilio_file.value) {
          return window.alert("El comprobante de domicilio es obligatorio");
        }

        const body = new FormData(e.target);
        body.forEach((value, key) => (formData[key] = value));

        //Validar RFC y Razon Social
        if (!checkvalue.value) {
          formData.razon_social = "AL PÚBLICO GENERAL";
          formData.rfc_sat = "XAXX010101000";
        }

        let contact = {
          name: formData.name,
          display_name: formData.name,
          phone: formData.phone,
          mobile: formData.mobile,
          razon_social: formData.razon_social,
          vat: formData.rfc_sat,
          tipo_cliente: "RESIDENCIAL",
          team_id: 1,
          country_id: 156,
          cliente: true,
          active: true,
          zip: formData.cp_partner,
          state_id: parseInt(formData.state_id_partner),
          city_id: parseInt(formData.city_id_partner),
          l10n_mx_edi_locality_id: parseInt(formData.l10n_mx_edi_locality_id),
          l10n_mx_edi_colony: formData.l10n_mx_edi_colony,
          street_number2: formData.street_number2,
          street_number: formData.street_number,
          street_name: formData.street_name,
        };

        console.log("******");
        console.log(contact);
        // console.log(props.state.coverage);
        console.log("******");

        const { ["data"]: partner_id } = await axios.post(
          "lead/contact",
          contact
        );
        formData.partner_id = partner_id.payload;

        console.log("******");
        console.log(partner_id.payload);
        console.log("******");

        //Conseguimios los base 64 de los archivos

        const idForm = new FormData();
        idForm.append("file", formData.identificacion_file);
        const { ["data"]: identificacion_file } = await axios.post(
          "lead/get64",
          idForm
        );
        formData.identificacion_file = identificacion_file.payload;

        if (
          checkvalue.value &&
          e.target.constancia_situacion_fiscal_file.value
        ) {
          const cedula = new FormData();
          cedula.append("file", formData.constancia_situacion_fiscal_file);
          const { ["data"]: cedula_data } = await axios.post(
            "lead/get64",
            cedula
          );
          formData.constancia_situacion_fiscal_file = cedula_data.payload;
        }

        if (e.target.comprobante_domicilio_file.value) {
          const comprobante = new FormData();
          comprobante.append("file", formData.comprobante_domicilio_file);
          const { ["data"]: comprobante_domicilio } = await axios.post(
            "lead/get64",
            comprobante
          );
          formData.comprobante_domicilio_file = comprobante_domicilio.payload;
        }

        //Eliminamos propiedades
        delete formData.street_name;
        delete formData.street_number;
        delete formData.street_number2;
        delete formData.l10n_mx_edi_colony;
        delete formData.l10n_mx_edi_locality_id;
        delete formData.city_id_partner;
        delete formData.state_id_partner;
        delete formData.cp_partner;

        // Query params
        const {
          tracking_id,
          utm_content,
          utm_medium,
          utm_source,
          utm_term,
          utm_campaign,
        } = route.query;

        const queryParams = {
          vendedor_preventa: route?.query?.id || formData.vendedor_preventa,
          tracking_id,
          utm_content,
          utm_medium,
          utm_source,
          utm_term,
          utm_campaign,
        };

        const requestData = {
          ...formData,
          ...queryParams,
          location_of_service: "otro",
          stage_id: 1,
          source_id: formData.source_id,
          type: "lead",
          partner_name: formData.name,
          contact_name: formData.name,
          type_service: "RESIDENCIAL",
          type_tecno_plan_servicio: "FIBRA",
          tarifa_id: parseInt(store.plan_id),
          plan_servicio: parseInt(plan_request.value.plan_servicio_internet[0]),
          precio_plan: plan_request.value.renta_mensual_con_iva,
          sin_costo_de_instalacion: plan_request.value.sin_costo_instalacion,
          monto_aux_costo_instalacion: plan_request.value.instalacion_sin_iva,
          network_coverage: true,
          estado_servicio: "QUINTANA ROO",
          asentamiento_id: parseInt(store.asentamiento_id),
          asentamiento_servicio: store.asentamiento_name,
          ciudad_cobertura_id: parseInt(store.zona_id),
          build_id: parseInt(store.building_id),
          tipo_asentamiento_id: typeLocality.value[0].value,
          rfc_fiscal_aux: formData.rfc_sat,
          razon_fiscal_aux: formData.razon_social,
          channel_other_option: channel_other_option.value,
          channel_contact: channel_id.value,
        };

        console.log("+++++");
        console.log(requestData);
        console.log("+++++");

        const { data } = await axios.post("lead/", requestData);
        console.log("+****+");
        console.log(data.payload);
        console.log("+****+");

        if (store.cobertura) {
          console.log("store.cobetura is true...");
          await axios.post(`lead/${data.payload}`, {
            id: parseInt(data.payload),
            stage_id: 4,
            type: "opportunity",
          });
          emit("next", { lead_id: data.payload });
        } else {
          emit("end", { lead_id: data.payload });
        }
      } catch (error) {
        console.log(error.message);
      } finally {
        store.APP_LOADING = false;
      }
    };

    return {
      listCoverageZones,
      listLocalities,
      localitiesOptions,
      checkvalue,
      loading,
      onSubmit,
      checkValueConfirm,
      id_vendedor,
      currentCoverage,
      currentLocality,
      typeLocality,
      sources,
      serviceAddress,
      getCountries,
      countries,
      country_id_partner,
      getStates,
      estados,
      state_id_partner,
      cities,
      city_id_partner,
      changeState,
      localities,
      locality_id_partner,
      plan_request,
      backStep,
      channel_other_option,
      channel_id,
    };
  },
};
</script>

<style lang="scss" scoped>
h1 {
  @apply text-bluegray-500;
  @apply font-semibold;
  @apply text-xl;
  @apply mb-3;
}
.row {
  @screen md {
    @apply grid;
    @apply gap-4;
    @apply grid-flow-col;
    @apply auto-cols-fr;
  }
  &.margin {
    @apply mt-2;
    @apply mb-5;
  }
}

.bg-gignetgreen-500 {
  background-color: rgba(21, 199, 208, var(--tw-bg-opacity));
}
.bg-gignetgreen-500:hover {
  background-color: rgba(18, 172, 180, var(--tw-bg-opacity));
}
.box-address {
  margin-top: 10px;
  margin-bottom: 10px;
}

.margin-badge {
  margin-left: 2px;
  margin-right: 2px;
}

.btns-steps {
  margin-top: 30px;
}

.form-input {
  @apply inline-block;
  @apply w-full;
  &__required {
    @apply text-red-400;
  }
}

.input {
  @apply mb-3;
  @apply block;
  @apply w-full;
  @apply rounded-md;
  @apply border-gray-300;
  @apply shadow-sm;
  @apply px-3;
  @apply py-2;
  @apply text-bluegray-700;
  @apply text-sm;
  @apply transition-all;
  @apply outline-none;
  &:focus:invalid {
    @apply border-red-300;
    @apply shadow-red-300-md;
  }
  &:focus {
    @apply border-gignetblue-300;
    @apply ring;
    @apply ring-gignetblue-200;
    @apply ring-opacity-50;
    @apply ring-offset-2;
  }

  &.disabled {
    @apply bg-bluegray-50;
    @apply text-bluegray-400;
  }
}

.label {
  @apply text-bluegray-400;
  @apply uppercase;
  @apply text-sm;
  @apply font-medium;
  @apply mb-2;
  @apply inline-block;
  @apply tracking-wide;
}
</style>
