<template lang="pug">
WizardHeader(:steps="items" :active="modelValue")
slot(:active="modelValue" :nextPage="nextPage" :previousPage="previousPage" :end="end" :state="state")
</template>

<script>
import { reactive } from "@vue/reactivity";
import WizardHeader from "./Header.vue";
export default {
  components: { WizardHeader },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    modelValue: {
      type: Number,
      default: 0,
    },
  },
  setup(props, { emit }) {
    let state = reactive({});

    function nextPage(e) {
      if (props.modelValue < props.items.length - 1) {
        for (const key in e) {
          state[key] = e[key];
        }
        emit("update:modelValue", props.modelValue + 1);
      }
    }
    function previousPage() {
      if (props.modelValue > 0) {
        emit("update:modelValue", props.modelValue - 1);
      }
    }
    function end(e = {}) {
      for (const key in e) {
        state[key] = e[key];
      }
      emit("update:modelValue", props.items.length - 1);
    }

    return { nextPage, previousPage, end, state };
  },
};
</script>
