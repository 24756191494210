<template lang="pug">
.contract
  AppCard.mb-4
    .flex.justify-center
      AppRow
        .buttonPDF(@click="prev", :class="{ disabled: currentPage == 1 }")
          i.uil.uil-angle-left
        .buttonPDF(@click="next", :class="{ disabled: currentPage == 8 }")
          i.uil.uil-angle-right
    pdf(:src="contractPDF", :page="currentPage")
  AppCard.mb-4(v-if="state.lead_id")
    .contract__title Link del contrato
    AppRow
      AppFormInput(type="text", :value="contractRoute", readOnly)
      .icon__copy(@click="copyToClickBoard")
        i.uil.uil-copy
  AppCard
    .contract__title Firma
    .sing
      VueSignaturePad(ref="signaturePad", :options="strokeOptions")
      .sing__line
    AppCheckbox.mt-4(
      :label="$t('signature.check')",
      v-model="checkValueConfirm"
    )
  AppRow.mt-5
    AppButton.my-3.danger(
      :label="$t('signature.clear')",
      @click="clearSignature"
    ) 
    AppButton.my-3(
      :label="$t('signature.save')",
      :loading="loading",
      :disabled="loading || !checkValueConfirm",
      @click="saveSignature"
    ) 
</template>

<script>
import { computed, onMounted, ref } from "@vue/runtime-core";
import { axios } from "@/utils/axios";
import { store } from "@/store";
import pdf from "vue3-pdf";
import AppCheckbox from "@/components/Form/AppCheckbox";
import AppFormInput from "@/components/Form/AppFormInput";
import AppButton from "@/components/AppButton";
import AppRow from "@/components/AppRow";
import AppCard from "@/components/AppCard";
import { useRoute, useRouter } from "vue-router";
export default {
  props: {
    state: {
      type: Object,
      default: () => ({}),
    },
  },
  components: { pdf, AppButton, AppRow, AppCard, AppCheckbox, AppFormInput },
  setup(props, { emit }) {
    const checkValueConfirm = ref(false);
    const contractRoute = ref("");
    const route = useRoute();
    const router = useRouter();
    const lead_id = ref(null);
    const contractPDF = ref("");
    const signValue = ref("");
    const currentPage = ref(1);
    const signaturePad = ref("");
    const loading = computed(() => store.APP_LOADING);
    const strokeOptions = {
      penColor: "blue",
      onBegin: () => {
        window.dispatchEvent(new Event("resize"));
      },
    };

    function encode(data) {
      return encodeURIComponent(btoa(data));
    }

    function decode(data) {
      return parseInt(atob(decodeURIComponent(data)));
    }

    onMounted(async () => {
      console.log("In component Contract");
      lead_id.value = route.params?.id
        ? decode(route.params.id)
        : props.state.lead_id;
      contractRoute.value = `${window.location.protocol}//${
        window.location.host
      }${route.fullPath}/${encode(lead_id.value)}`;
      try {
        store.APP_LOADING = true;
        const { data } = await axios.post("lead/contract", {
          id: lead_id.value,
        });
        contractPDF.value = `data:application/pdf;base64,${data.payload}`;
      } catch (error) {
        console.log(error);
        route.params.id && router.push({ name: "Login" });
      } finally {
        store.APP_LOADING = false;
      }
    });

    const copyToClickBoard = () => {
      navigator.clipboard.writeText(contractRoute.value).then(() => {
        alert("Se ha copiado el vinculo");
      });
    };

    const prev = () => {
      if (currentPage.value < 2) return;
      currentPage.value--;
    };

    const next = () => {
      if (currentPage.value > 7) return;
      currentPage.value++;
    };

    const clearSignature = () => {
      signaturePad.value.clearSignature();
    };

    const saveSignature = async () => {
      const { isEmpty, data } = signaturePad.value.saveSignature();
      if (isEmpty) return;
      signValue.value = data.replaceAll("data:image/png;base64,", "");
      try {
        store.APP_LOADING = true;
        await axios.post("lead/contract/sign", {
          id: lead_id.value,
          sign: signValue.value,
        });

        Promise.allSettled([
          await axios.post("lead/invoice", {
            id: lead_id.value,
          }),
          await axios.post("lead/send", {
            id: lead_id.value,
          }),
        ]);

        emit("next", {});
      } catch (error) {
        console.log(error);
      } finally {
        store.APP_LOADING = false;
      }
    };

    return {
      checkValueConfirm,
      contractPDF,
      currentPage,
      strokeOptions,
      signaturePad,
      clearSignature,
      saveSignature,
      prev,
      next,
      signValue,
      loading,
      contractRoute,
      copyToClickBoard,
    };
  },
};
</script>

<style lang="scss" scoped>
.contract {
  &.margin {
    @apply mt-2;
    @apply mb-5;
  }
  &__title {
    @apply text-bluegray-400;
    @apply uppercase;
    @apply text-sm;
    @apply font-medium;
    @apply mb-2;
    @apply inline-block;
    @apply tracking-wide;
  }
  .buttonPDF {
    @apply text-center;
    @apply h-6;
    @apply w-6;
    @apply bg-gignetgreen-200;
    @apply rounded-full;
    @apply text-gignetblue-400;
  }
  .disabled {
    @apply bg-gray-100;
    @apply text-gray-400;
    @apply cursor-not-allowed;
  }
  .icon__copy {
    @apply bg-gray-100;
    @apply w-10;
    @apply h-9;
    @apply rounded-full;
    @apply text-center;
    @apply pt-1;
    i {
      @apply text-gray-600;
    }
  }
}
.sing {
  @apply rounded-lg;
  @apply bg-gignetblue-100;
  @apply h-28;
  &__line {
    margin-top: -25px;
    @apply border-dashed;
    @apply mx-2;
    @apply border-t-2;
    @apply border-gignetblue-200;
    @apply h-4;
  }
}
</style>
