<template>
  <label class="file-upload" @click="open">
    <template v-if="imageUrl">
      <!-- <i class="uil uil-times" @click.prevent="reset"></i> -->
      <img :src="imageUrl" />
    </template>
    <template v-else>
      <i :class="iconClass"></i>
      <span>{{ label }}</span>
    </template>
    <input
      :accept="accept"
      type="file"
      ref="fileupload"
      :name="name"
      class="hidden"
      @change="setFile"
    />
  </label>
</template>

<script>
import { computed, onMounted, ref } from "vue";
export default {
  props: {
    icon: {
      type: String,
    },
    label: {
      type: String,
    },
    name: {
      type: String,
    },
    accept: {
      type: String,
    },
  },
  setup(props) {
    const fileupload = ref(null);
    const imageUrl = ref(null);
    onMounted(() => {
      fileupload.value = null;
      imageUrl.value = null;
    });
    const setFile = (e) => {
      const file = e.target.files[0];
      if (file?.type == "image/png" || file?.type == "image/jpg") {
        imageUrl.value = URL.createObjectURL(file);
      } else {
        imageUrl.value =
          "https://icons-for-free.com/iconfiles/png/512/check+document+file+icon+icon-1320196654394535651.png";
      }
    };

    const open = () => {
      fileupload.value.click();
    };

    const reset = () => {
      imageUrl.value = null;
    };
    const iconClass = computed(() => {
      return `uil uil-${props.icon}`;
    });
    return {
      iconClass,
      setFile,
      open,
      reset,
      imageUrl,
    };
  },
};
</script>
<style lang="scss" scoped>
.file-upload {
  @apply rounded-md;
  @apply border-2;
  @apply border-dashed;
  @apply border-lightblue-300;
  @apply bg-lightblue-50;
  @apply w-full;
  @apply h-auto;
  @apply cursor-pointer;
  @apply flex;
  @apply justify-center;
  @apply items-center;
  @apply py-3.5;
  @apply transition-all;
  @apply flex-col;
  @apply text-center;
  @apply relative;
  &:hover {
    @apply border-lightblue-400;
    @apply bg-lightblue-50;
    i {
      @apply text-lightblue-400;
    }
  }
  &:active {
    @apply border-lightblue-300;
    i {
      @apply text-lightblue-300;
    }
  }
  i {
    @apply text-lightblue-300;
    @apply text-4xl;
    @apply transition-all;
    @apply inline-block;
    @apply mb-1;
    &.uil-times {
      @apply absolute;
      @apply right-2;
      @apply top-2;
      @apply text-lg;
    }
  }
  span {
    @apply text-lightblue-500;
    @apply text-sm;
  }
  img {
    @apply h-20;
  }
}
</style>
