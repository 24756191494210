<template lang="pug">
form(@submit.prevent="onSubmit")
	AppCard
		h1 {{$t('wizard_contract.contact.title')}}
		.row
			AppFormInput(type="text" :label="$t('wizard_contract.contact.name')" name="name" required)
			AppFormInput(type="email" :label="$t('wizard_contract.contact.email')" name="email" required)
		.row
			AppFormInput(type="text" :label="$t('wizard_contract.contact.phone')" name="phone" required)
			//- AppFormInput(type="select" :label="$t('contract.address_form.referal')" :options="sources" name="channel_contact" required)
			div
				span(class="label") {{$t('contract.address_form.referal')}}
				select(name="channel_contact" v-model="channel_id" class="input")
					option(:value="item.value" v-for="item in sources" :key="item.value")
						| {{item.label}}
			div(v-show="channel_id == 'otro'")
				span(class="label")
					| {{ $t('contract.address_form.channel_other') }}
				span(class="form-input__required")
					| *
				input(type="text" label="Canal" v-model="channel_other_option" class="input" :required="channel_id == 'otro'")
		.row
			AppFormInput(type="text" :label="$t('wizard_contract.contact.colony')" name="colony" required)
			AppFormInput(type="text" :label="$t('wizard_contract.contact.city')" name="city" required)
			AppFormInput(type="text" :label="$t('wizard_contract.contact.building')" name="building")
			AppFormInput(type="text" :label="$t('wizard_contract.contact.zip')" name="zip" required)
		.row
			label(class="form-input")
				.row(class="row-gps")
					span(class="label" @mouseover="showTooltip = true" @mouseleave="showTooltip = false" @click="showTooltip = !showTooltip") 
						| {{$t('wizard_contract.contact.link_maps')}}
						i(class="uil uil-question-circle")
						//- span(class="form-input__required") *
						span(class="tooltip-container" v-show="showTooltip")
							small(class="tooltip-body")
								ul(class="list-decimal list-inside text-slate-700 rounded-xl shadow-lg ring-1 ring-slate-900/5 p-4 pl-8 dark:bg-slate-800 dark:ring-0 dark:highlight-white/5 dark:text-slate-400")
									li()
										| {{$t('wizard_contract.contact.gps_steps.one')}}
									li()
										| {{$t('wizard_contract.contact.gps_steps.two')}}
									li()
										| {{$t('wizard_contract.contact.gps_steps.three')}}
									li()
										| {{$t('wizard_contract.contact.gps_steps.four')}}
									li()
										| {{$t('wizard_contract.contact.gps_steps.five')}}
					
					span(class="label container-marker")
						span(class="text-marker")
							| {{$t('wizard_contract.contact.info_gps')}}
						span(class="marker" @click="getLocation")
							i(class="uil uil-map-marker-minus")
				input(type="text" v-model="link_maps" class="input")
				//- AppFormInput(type="text" v-model="link_maps" name="coordenadas_contact" required)
		AppFormInput(type="textarea" :label="$t('wizard_contract.contact.comment')" name="comment")
		AppRow.mt-5.btns-steps
			AppButton.mb-0.bg-gignetgreen-500(:label="$t('wizard_contract.address.btn_back')" @click="backStep")
			AppButton.mb-0.bg-gignetgreen-500(:loading="loading" :disabled="loading" :label="$t('wizard_contract.contact.btn_send')")
</template>
<script>
import AppCard from "@/components/AppCard";
import AppPlan from "@/components/Plans/Plan";
import AppFormInput from "@/components/Form/AppFormInput";
import AppButton from "@/components/AppButton";
import AppRow from "@/components/AppRow";
import { computed, onMounted, ref } from "vue";
import { store } from "@/store";
import { axios } from "@/utils/axios";
export default {
  components: { AppCard, AppPlan, AppFormInput, AppButton, AppRow },
  props: {
    state: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const loading = computed(() => store.APP_LOADING);
    const sources = ref([]);
    const channel_id = ref("website");
    const link_maps = ref("");
    const showTooltip = ref(false);
    const channel_other_option = ref("");

    onMounted(async () => {
      console.log(store.cobertura);
      if (store.cobertura) {
        emit("next");
      } else {
        if (store.step == 2) {
          emit("previous");
        } else {
          store.step = 1;
        }
      }

      const { data: sourcesArr } = await axios.get("source/channels");
      let items = sourcesArr.payload.map((el) => ({
        value: el.id,
        label: el.name,
      }));

      sources.value = items.sort(function (a, b) {
        var x = a["label"],
          y = b["label"];
        return x < y ? -1 : x > y ? 1 : 0;
      });

      // console.log("-----");
      // console.log(sources.value);
      // console.log("-----");
    });

    const backStep = async () => {
      console.log("backStep...");
      emit("previous");
    };

    const getLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          link_maps.value =
            "https://www.google.com.mx/maps/@" +
            latitude +
            "," +
            longitude +
            ",20.5z";
          // link_maps.value = latitude + ", " + longitude;
          console.log(link_maps.value);
        });
      } else {
        alert("Tu navegador no soporta geolocalización.");
      }
    };

    const onSubmit = async (e) => {
      try {
        console.log("Submit..");
        store.APP_LOADING = true;
        const formData = {};
        const body = new FormData(e.target);
        body.forEach((value, key) => (formData[key] = value));

        let contactInformation = {
          name: formData.name,
          email: formData.email,
          phone: formData.phone,
          mobile: formData.phone,
          street_name: formData.city + " " + formData.colony,
          zip: formData.zip,
          comment: formData.comment,
          country_id: 156,
        };

        console.log(contactInformation);

        const { data } = await axios.post("lead/contact", contactInformation);

        console.log("-----");
        console.log(data.payload);
        console.log("-----");

        let leadData = {
          partner_id: parseInt(data.payload),
          name: formData.name,
          stage_id: 1,
          type: "lead",
          partner_name: formData.name,
          contact_name: formData.name,
          type_service: "RESIDENCIAL",
          type_tecno_plan_servicio: "FIBRA",
          estado_servicio: "QUINTANA ROO",
          network_coverage: false,
          description: formData.comment,
          cp_servicio: formData.zip,
          anexo_direccion_colonia: formData.colony,
          ciudad_servicio: formData.city,
          channel_contact: channel_id.value,
          coordenadas_contact: link_maps.value,
          anexo_direccion_tipo: formData.building,
          channel_other_option: channel_other_option.value,
        };
        console.log(leadData);
        const { data: lead_id } = await axios.post("lead/", leadData);
        console.log("-----");
        console.log(lead_id);
        console.log("-----");
        emit("end", { lead_id: lead_id.payload });
      } catch (error) {
        console.log(error.message);
      } finally {
        store.APP_LOADING = false;
      }
    };

    return {
      loading,
      onSubmit,
      backStep,
      sources,
      channel_id,
      link_maps,
      getLocation,
      showTooltip,
      channel_other_option,
    };
  },
};
</script>
<style lang="scss" scoped>
.plan-container {
  @apply grid grid-flow-row gap-x-8 gap-y-10 px-6;
  @screen md {
    @apply grid-cols-2;
  }
}

h1 {
  @apply text-bluegray-500;
  @apply font-semibold;
  @apply text-xl;
  @apply mb-3;
}
.row {
  @screen md {
    @apply grid;
    @apply gap-4;
    @apply grid-flow-col;
    @apply auto-cols-fr;
  }
  &.margin {
    @apply mt-2;
    @apply mb-5;
  }
}

.bg-gignetgreen-500 {
  background-color: rgba(21, 199, 208, var(--tw-bg-opacity));
}
.bg-gignetgreen-500:hover {
  background-color: rgba(18, 172, 180, var(--tw-bg-opacity));
}

.form-input {
  @apply inline-block;
  @apply w-full;
  &__required {
    @apply text-red-400;
  }
}

.input {
  @apply mb-3;
  @apply block;
  @apply w-full;
  @apply rounded-md;
  @apply border-gray-300;
  @apply shadow-sm;
  @apply px-3;
  @apply py-2;
  @apply text-bluegray-700;
  @apply text-sm;
  @apply transition-all;
  @apply outline-none;
  &:focus:invalid {
    @apply border-red-300;
    @apply shadow-red-300-md;
  }
  &:focus {
    @apply border-gignetblue-300;
    @apply ring;
    @apply ring-gignetblue-200;
    @apply ring-opacity-50;
    @apply ring-offset-2;
  }

  &.disabled {
    @apply bg-bluegray-50;
    @apply text-bluegray-400;
  }
}

.label {
  @apply text-bluegray-400;
  @apply uppercase;
  @apply text-sm;
  @apply font-medium;
  @apply mb-2;
  @apply inline-block;
  @apply tracking-wide;
}

.row-gps {
  grid-auto-columns: auto !important;
}

.container-marker {
  // background-color: yellow;
}

.marker {
  text-align: right;
  color: blue !important;
  // margin-left: 50%;
  font-size: 25px;
  // background-color: green;
}

.text-marker {
  font-size: 8px;
  text-align: right;
  // background-color: red;
}

.tooltip-container {
  position: inherit; /* Establecemos el contenedor como relativo */
}

.tooltip-body {
  color: grey !important;
  transition-duration: 0.3s;
  background-color: white !important;
  text-transform: none !important;
  position: absolute; /* Establecemos el tooltip como absoluto */
  z-index: 1; /* Establecemos una capa superior con z-index */
  display: inline-block;
  width: auto;
}

li {
  display: list-item;
  text-align: -webkit-match-parent;
}

// .input-address {
// }

// .input-zip {
// }
</style>
