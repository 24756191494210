<template lang="pug">
.contenedor
    img.gignet-logo(:src="require('@/assets/logo.png')")
    Wizard(:items="steps" v-model="activePage")
        template(#default="{active, previousPage, nextPage, end, state}")
            component(:is="currentPage" @previous="previousPage" @next="nextPage" @end="end" :key="activePage" :state="state")
</template>
<script>
import { ref } from "@vue/reactivity";
import { computed, onMounted } from "@vue/runtime-core";
import { useRoute } from "vue-router";
import Wizard from "@/components/Wizard/Wizard";
import Address from "./Address.vue";
import Plans from "./Plans.vue";
import Customer from "./Customer.vue";
import Contract from "./Contract.vue";
import End from "./End.vue";
import Contact from "./Contact.vue";
import { useI18n } from "vue-i18n";
export default {
  components: { Wizard },
  setup() {
    const route = useRoute();
    const activePage = ref(null);

    onMounted(() => {
      activePage.value = route.params.id ? 4 : 0;
    });

    const currentPage = computed(() => {
      const pages = [Address, Contact, Plans, Customer, Contract, End];
      return pages[activePage.value];
    });

    const { t } = useI18n();

    const steps = [
      {
        label: t("wizard_contract.steps.address"),
        icon: "uil uil-map-marker",
      },
      {
        label: t("wizard_contract.steps.contact"),
        icon: "uil uil-edit",
      },
      {
        label: t("wizard_contract.steps.plans"),
        icon: "uil uil-pricetag-alt",
      },
      {
        label: t("wizard_contract.steps.customer"),
        icon: "uil uil-user",
      },
      {
        label: t("wizard_contract.steps.contract"),
        icon: "uil uil-shield-check",
      },
      {
        label: t("wizard_contract.steps.end"),
        icon: "uil uil-check-circle",
      },
    ];

    return { steps, activePage, currentPage };
  },
};
</script>
<style lang="scss" scoped>
.contenedor {
  .gignet-logo {
    @apply w-1/2;
    @apply mx-auto;
    @screen md {
      @apply w-1/3;
    }
  }
  @apply mx-auto;
  @screen lg {
    @apply w-3/4;
  }
}
</style>
