<template lang="pug">
AppCard
	h1.text-center.title-step {{$t('wizard_contract.address.title')}}
	//- AppFormInput(type="select" v-model:value="zona_id" :label="$t('wizard_contract.address.city')" :options="zonas" required @change="filterAsentamientos")
	//- AppFormInput(type="select" v-model:value="asentamiento_id" :label="$t('wizard_contract.address.zone')" :options="options_asentamientos" required @change="filterBuildings")
	//- AppFormInput(type="select" v-model:value="building_id" :label="$t('wizard_contract.address.building')" :options="options_buildings" required)
	span(class="label") {{$t('wizard_contract.address.city')}}
	select(name="zona_id" v-model="zona_id" class="input" @change="filterAsentamientos")
		option(:value="item.value" v-for="item in zonas" :key="item.value")
			| {{item.label}}
	span(class="label") {{$t('wizard_contract.address.zone')}}
	select(name="asentamiento_id" v-model="asentamiento_id" class="input" @change="filterBuildings")
		option(:value="item.value" v-for="item in options_asentamientos" :key="item.value")
			| {{item.label}}
	span(class="label") {{$t('wizard_contract.address.building')}}
	select(name="building_id" v-model="building_id" class="input")
		option(:value="item.value" v-for="item in options_buildings" :key="item.value")
			| {{item.label}}
	AppRow.mt-5
		AppButton.mb-0.bg-gignetgreen-500(@click="backStep" :disabled="true" :label="$t('wizard_contract.address.btn_back')")
		AppButton.mb-0.bg-gignetgreen-500(:loading="loading" :disabled="loading" :label="$t('wizard_contract.address.btn_send')" @click="onSubmit")
	AppCard.card-form-link.mt-5
		.flex.justify-center.title-head
			| {{$t('wizard_contract.address.box_header_text')}}
		.flex.justify-center
			| {{$t('wizard_contract.address.box_body_text')}}
		AppRow.mt-5
			AppButton.mb-0.bg-gignetgreen-500.text-bold(:loading="loading" :disabled="loading" :label="$t('wizard_contract.address.btn_fillform')" @click="fillForm")
</template>
<script>
import AppCard from "@/components/AppCard";
import AppButton from "@/components/AppButton";
import AppFormInput from "@/components/Form/AppFormInput";
import AppRow from "@/components/AppRow";
import { computed, ref, onMounted } from "@vue/runtime-core";
import { store } from "@/store";
import { axios } from "@/utils/axios";
export default {
  props: {
    state: {
      type: Object,
      default: () => ({}),
    },
  },
  components: { AppCard, AppButton, AppFormInput, AppRow },
  setup(props, { emit }) {
    const selected = ref("");
    const loading = computed(() => store.APP_LOADING);
    const country_id = ref("156");
    const state_id = ref("506");
    let city_id = ref("1808");
    let zona_id = ref("2");
    let zonas = ref([]);
    let asentamiento_id = ref("17");
    let asentamientos = ref([]);
    let building_id = ref("");
    let buildings_complex = ref([]);
    let options_asentamientos = ref([]);
    let options_buildings = ref([]);

    const getZonas = async () => {
      const { data } = await axios.get("lead/zonacobertura");
      let items = data.payload.map((el) => ({
        value: el.id.toString(),
        label: el.name,
      }));

      zonas.value = items.sort(function (a, b) {
        var x = a["label"],
          y = b["label"];
        return x < y ? -1 : x > y ? 1 : 0;
      });
    };

    const getAsentamientos = async () => {
      const { data } = await axios.get("lead/asentamientos");
      let items = data.payload.map((el) => ({
        value: el.id.toString(),
        label: el.name,
        zona_id: el.ciudad_cobertura_id[0],
      }));

      asentamientos.value = items.sort(function (a, b) {
        var x = a["label"],
          y = b["label"];
        return x < y ? -1 : x > y ? 1 : 0;
      });
    };

    const getBuildings = async () => {
      const { data } = await axios.get(`location/building`);
      let items = data.payload.map((el) => ({
        value: el.id.toString(),
        label: el.name,
        asentamiento_id: el.asentamiento_id[0],
      }));

      buildings_complex.value = items.sort(function (a, b) {
        var x = a["label"],
          y = b["label"];
        return x < y ? -1 : x > y ? 1 : 0;
      });
    };

    const filterAsentamientos = async () => {
      options_asentamientos.value = asentamientos.value.filter(
        (el) => el.zona_id == zona_id.value
      );
    };

    const filterBuildings = async () => {
      // console.log(options_buildings.value);
      options_buildings.value = buildings_complex.value.filter(
        (el) => el.asentamiento_id == asentamiento_id.value
      );
    };

    onMounted(async () => {
      try {
        store.APP_LOADING = true;
        store.cobertura = false;
        store.step = 0;
        await getZonas();
        await getAsentamientos();
        await getBuildings();
        //
        await filterAsentamientos();
        await filterBuildings();
      } catch (error) {
        console.log(error);
      } finally {
        store.APP_LOADING = false;
      }
    });

    const backStep = async () => {
      console.log("backStep...");
    };

    const fillForm = async () => {
      emit("next");
    };

    const onSubmit = async () => {
      try {
        if (
          city_id.value != "" &&
          zona_id.value != "" &&
          asentamiento_id.value != "" &&
          building_id.value != ""
        ) {
          store.cobertura = true;
          localStorage.country_id = store.cobertura;
          // Ids
          store.country_id = country_id.value;
          store.state_id = state_id.value;
          store.city_id = city_id.value;
          store.zona_id = zona_id.value;
          store.asentamiento_id = asentamiento_id.value;
          store.building_id = building_id.value;

          localStorage.country_id = store.country_id;
          localStorage.state_id = store.state_id;
          localStorage.city_id = store.city_id;
          localStorage.zona_id = store.zona_id;
          localStorage.asentamiento_id = store.asentamiento_id;
          localStorage.building_id = store.building_id;

          // names
          store.city_name = "Quintana Roo";

          store.zona_name = await zonas.value.find(
            (element) => element.value == zona_id.value
          ).label;

          store.asentamiento_name = await asentamientos.value.find(
            (element) => element.value == asentamiento_id.value
          ).label;

          store.building_name = await buildings_complex.value.find(
            (element) => element.value == building_id.value
          ).label;

          localStorage.city_name = store.city_name;
          localStorage.zona_name = store.zona_name;
          localStorage.asentamiento_name = store.asentamiento_name;
          localStorage.building_name = store.building_name;

          emit("next");
        } else {
          alert("Todos los campos son requeridos.");
        }
      } catch (error) {
        console.log(error.message);
      } finally {
        store.APP_LOADING = false;
      }
    };

    return {
      selected,
      loading,
      country_id,
      state_id,
      city_id,
      zona_id,
      zonas,
      asentamiento_id,
      asentamientos,
      building_id,
      buildings_complex,
      backStep,
      fillForm,
      onSubmit,
      options_asentamientos,
      filterAsentamientos,
      options_buildings,
      filterBuildings,
    };
  },
};
</script>
<style lang="scss" scoped>
.title-step {
  font-weight: bold;
  color: #2a5aad;
  margin-bottom: 20px;
}

.bg-gignetgreen-500 {
  background-color: rgba(21, 199, 208, var(--tw-bg-opacity));
}
.bg-gignetgreen-500:hover {
  background-color: rgba(18, 172, 180, var(--tw-bg-opacity));
}
.card-form-link {
  background-color: #2a5aad;
  color: white !important;
}

.title-head {
  font-size: 22px;
  font-weight: bold;
}

.text-bold {
  font-weight: bold;
}

.form-input {
  @apply inline-block;
  @apply w-full;
  &__required {
    @apply text-red-400;
  }
}

.input {
  @apply mb-3;
  @apply block;
  @apply w-full;
  @apply rounded-md;
  @apply border-gray-300;
  @apply shadow-sm;
  @apply px-3;
  @apply py-2;
  @apply text-bluegray-700;
  @apply text-sm;
  @apply transition-all;
  @apply outline-none;
  &:focus:invalid {
    @apply border-red-300;
    @apply shadow-red-300-md;
  }
  &:focus {
    @apply border-gignetblue-300;
    @apply ring;
    @apply ring-gignetblue-200;
    @apply ring-opacity-50;
    @apply ring-offset-2;
  }

  &.disabled {
    @apply bg-bluegray-50;
    @apply text-bluegray-400;
  }
}

// .invalid {
//   @apply border-red-300;
//   @apply shadow-red-300-md;
// }

.label {
  @apply text-bluegray-400;
  @apply uppercase;
  @apply text-sm;
  @apply font-medium;
  @apply mb-2;
  @apply inline-block;
  @apply tracking-wide;
}
</style>
